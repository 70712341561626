.nav-toggle {
  position: absolute;
  cursor: pointer;
  margin-top: 25px;
  margin-left: 20px;
}

.nav-toggle .bar {
  position: absolute;
  width: 20px;
  height: 4px;
  background-color: #5c5c5c;
  transition: all 0.5s ease;
}

.nav-toggle .bar:nth-child(1) {
  transform: translateY(-150%);
}

.nav-toggle .bar:nth-child(3) {
  transform: translateY(150%);
}

.nav-toggle.change .bar:nth-child(1) {
  transform: rotate(45deg);
}

.nav-toggle.change .bar:nth-child(2) {
  opacity: 0;
}

.nav-toggle.change .bar:nth-child(3) {
  transform: rotate(-45deg);
}

.navigation {
  background-color: white;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 70px;
  z-index: 1000;
  will-change: transform;
  transition: transform 0.3s linear;
  margin: 0;
}

.mobile-navigation {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  transition: transform 0.3s ease-in-out;
  width: 100vw;
  height: 100vh;
  transform: translateX(-100%);
}

.open {
  display: flex;
  transform: translateX(0);
}

.closed {
  display: none;
  transform: translateX(-100%);
}

.navigation-content {
  width: 100%;
}

.navigation-menu {
  display: none;
}

.nav-link {
  text-align: left;
  margin: 5px auto;
  width: 90%;
  font-size: 23px;
  text-decoration: none;
  color: #5c5c5c;
  padding-bottom: 10px;
  border-bottom: #8be37b solid 1px;
}

.navigation-logo img {
  display: block;
  margin: 0 auto;
  width: auto;
  padding-top: 10px;
}

.navigation-contact-details {
  display: none;
}

.contact-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0;
  justify-content: center;
}

.contact-details a {
  text-decoration: none;
  font-size: 15px;
  color: #5c5c5c;
  padding-left: 5px;
}

@media screen and (min-width: 1125px) {
  .navigation {
    height: 80px;
    position: fixed;
  }

  .navigation-pinned {
    transform: translateY(0%);
  }
  .navigation-unpinned {
    transform: translateY(-100%);
  }

  .navigation-contact-details {
    display: contents;
  }

  .contact-details {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 10px 0;
  }

  .contact-details a {
    text-decoration: none;
    font-size: 12px;
    color: #5c5c5c;
  }

  .mobile-navigation {
    display: none;
  }

  .navigation-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
  }

  .navigation-menu {
    display: flex;
    width: 75%;
    margin: 0 auto;
    background-color: white;
    padding: 18px 20px 20px;
  }

  .nav-link {
    padding: 0;
    border: none;
    text-align: center;
    width: auto;
    margin: 0 10px;
  }

  .nav-toggle {
    display: none;
  }

  .navigation-logo {
    flex: 0 0 12em;
    padding: 0;
    margin: 15px auto;
  }

  .navigation-logo img {
    padding-top: 0;
  }
}
