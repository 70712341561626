@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
  margin:0;
  padding:0;
}

.wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-content {
  background-color: white;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

@media screen and (min-width: 1000px) {
  .main-content {
    display: flex;
  }

  .container {
    display: flex;
    flex-direction: column;
  }
}

.nav-toggle {
  position: absolute;
  cursor: pointer;
  margin-top: 25px;
  margin-left: 20px;
}

.nav-toggle .bar {
  position: absolute;
  width: 20px;
  height: 4px;
  background-color: #5c5c5c;
  transition: all 0.5s ease;
}

.nav-toggle .bar:nth-child(1) {
  -webkit-transform: translateY(-150%);
          transform: translateY(-150%);
}

.nav-toggle .bar:nth-child(3) {
  -webkit-transform: translateY(150%);
          transform: translateY(150%);
}

.nav-toggle.change .bar:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.nav-toggle.change .bar:nth-child(2) {
  opacity: 0;
}

.nav-toggle.change .bar:nth-child(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.navigation {
  background-color: white;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 70px;
  z-index: 1000;
  will-change: transform;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  margin: 0;
}

.mobile-navigation {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.open {
  display: flex;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.closed {
  display: none;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.navigation-content {
  width: 100%;
}

.navigation-menu {
  display: none;
}

.nav-link {
  text-align: left;
  margin: 5px auto;
  width: 90%;
  font-size: 23px;
  text-decoration: none;
  color: #5c5c5c;
  padding-bottom: 10px;
  border-bottom: #8be37b solid 1px;
}

.navigation-logo img {
  display: block;
  margin: 0 auto;
  width: auto;
  padding-top: 10px;
}

.navigation-contact-details {
  display: none;
}

.contact-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0;
  justify-content: center;
}

.contact-details a {
  text-decoration: none;
  font-size: 15px;
  color: #5c5c5c;
  padding-left: 5px;
}

@media screen and (min-width: 1125px) {
  .navigation {
    height: 80px;
    position: fixed;
  }

  .navigation-pinned {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .navigation-unpinned {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  .navigation-contact-details {
    display: contents;
  }

  .contact-details {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 10px 0;
  }

  .contact-details a {
    text-decoration: none;
    font-size: 12px;
    color: #5c5c5c;
  }

  .mobile-navigation {
    display: none;
  }

  .navigation-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
  }

  .navigation-menu {
    display: flex;
    width: 75%;
    margin: 0 auto;
    background-color: white;
    padding: 18px 20px 20px;
  }

  .nav-link {
    padding: 0;
    border: none;
    text-align: center;
    width: auto;
    margin: 0 10px;
  }

  .nav-toggle {
    display: none;
  }

  .navigation-logo {
    flex: 0 0 12em;
    padding: 0;
    margin: 15px auto;
  }

  .navigation-logo img {
    padding-top: 0;
  }
}

.page-header {
  margin: 0;
  width: 100%;
}

.banner img,
.banner source {
  border-radius: 25px;
  max-width: 100%;
}

.left-content {
  font-size: 2rem;
  color: blue;
  margin: 15px 0;
}

.right-content {
  color: black;
  margin: 15px 0;
}

@media screen and (min-width: 1000px) {
  .page-header {
    display: flex;
    flex-direction: column;
  }

  .banner img,
  .banner source {
    flex: 1 1;
    width: 100%;
    max-height: 60vh;
  }

  .content-container {
    display: flex;
  }

  .left-content {
    flex: 0 1 30%;
  }

  .right-content {
    flex: 0 1 70%;
    margin-left: auto;
    order: 2;
  }
}

.section {
  border-top: #5c5c5c solid 1px;
  width: 100%;
  margin: 20px 0;
  padding-top: 20px;
  color: #5c5c5c;
}

.order {
  order: 2;
}

.thumbnail {
  width: 100%;
  min-height: 30vh;
  max-height: 45vh;
  border-radius: 25px;
}

.section-text {
  margin-left: auto;
  margin-right: auto;
}

.section-text h2 {
  display: block;
  margin: 20px auto;
  width: auto;
}

@media screen and (min-width: 1000px) {
  .section {
    display: flex;
  }

  .thumbnail {
    flex: 0 1 50%;
    width: 500px;
    height: 20vh;
    margin: 0 10px;
  }

  .section-text {
    flex: 0 1 50%;
    padding-left: 30px;
    padding-right: 40px;
  }
  .section-text p {
    font-size: 1.2rem;
  }

  .section-text h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.footer {
  display: flex;
  width: 100%;
  background-color: #5c5c5c;
  height: 40px;
  color: white;
  text-decoration: none;
  justify-content: center;
  padding-top: 5px;
}

.footer span a {
  text-decoration: none;
  color: white;
  margin: 0 10px;
}

@media screen and (max-width: 1000px) {
  .footer span a{
    font-size: 12px;
  }
}
