.page-header {
  margin: 0;
  width: 100%;
}

.banner img,
.banner source {
  border-radius: 25px;
  max-width: 100%;
}

.left-content {
  font-size: 2rem;
  color: blue;
  margin: 15px 0;
}

.right-content {
  color: black;
  margin: 15px 0;
}

@media screen and (min-width: 1000px) {
  .page-header {
    display: flex;
    flex-direction: column;
  }

  .banner img,
  .banner source {
    flex: 1;
    width: 100%;
    max-height: 60vh;
  }

  .content-container {
    display: flex;
  }

  .left-content {
    flex: 0 1 30%;
  }

  .right-content {
    flex: 0 1 70%;
    margin-left: auto;
    order: 2;
  }
}
