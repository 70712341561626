@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
  margin:0;
  padding:0;
}
