.wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-content {
  background-color: white;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

@media screen and (min-width: 1000px) {
  .main-content {
    display: flex;
  }

  .container {
    display: flex;
    flex-direction: column;
  }
}
