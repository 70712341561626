.footer {
  display: flex;
  width: 100%;
  background-color: #5c5c5c;
  height: 40px;
  color: white;
  text-decoration: none;
  justify-content: center;
  padding-top: 5px;
}

.footer span a {
  text-decoration: none;
  color: white;
  margin: 0 10px;
}

@media screen and (max-width: 1000px) {
  .footer span a{
    font-size: 12px;
  }
}