.section {
  border-top: #5c5c5c solid 1px;
  width: 100%;
  margin: 20px 0;
  padding-top: 20px;
  color: #5c5c5c;
}

.order {
  order: 2;
}

.thumbnail {
  width: 100%;
  min-height: 30vh;
  max-height: 45vh;
  border-radius: 25px;
}

.section-text {
  margin-left: auto;
  margin-right: auto;
}

.section-text h2 {
  display: block;
  margin: 20px auto;
  width: auto;
}

@media screen and (min-width: 1000px) {
  .section {
    display: flex;
  }

  .thumbnail {
    flex: 0 1 50%;
    width: 500px;
    height: 20vh;
    margin: 0 10px;
  }

  .section-text {
    flex: 0 1 50%;
    padding-left: 30px;
    padding-right: 40px;
  }
  .section-text p {
    font-size: 1.2rem;
  }

  .section-text h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
